import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import IconPlusBlackIcon from '../../assets/icons8-heart-24.png'

import css from './FavoriteIcon.module.css';

const FavoriteIcon = props => {
    const { rootClassName, className } = props;
    const classes = classNames(rootClassName || css.root, className);
  
    return (
      <img
        className={classes}
        src={IconPlusBlackIcon}
        alt="Plus Icon"
        width="16"
        height="17"
      />
    );
  };
  
  FavoriteIcon.defaultProps = { rootClassName: null, className: null };
  
  const { string } = PropTypes;
  
  FavoriteIcon.propTypes = {
    rootClassName: string,
    className: string,
  };
  
  export default FavoriteIcon;
